.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


.signin {
  display: flex;
  max-width: 320px;
  padding: 0.5rem 1.4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.25);
  gap: 0.75rem;
  color: #323232;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0, 0.87, 0.12, 1);
}

.signin:hover {
  transform: scale(1.025);
}

.signin:active {
  transform: scale(0.975);
}

