.main {
  display: flex;
  height: max-content;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: var(--background) !important;
}

.card {
  min-width: 15rem;
  flex-grow: 1;
}