.main {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.center-title{
  width: 100%;
  display: flex;
  justify-content: center;
}