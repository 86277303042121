html {
  overflow-y: hidden;
  height: 100%;
  height: -webkit-fill-available;
}

#root {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: var(--font-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  height: -webkit-fill-available;
  background-color: var(--background2);
}

svg {
  color: var(--primary);
  width: 2rem;
  height: 2rem;
  margin: 5px;
}




h1 {
  font-weight: 600;
  color: var(--text);
}

h2 {
  color: var(--text);
}

p {
  color: var(--text);
  margin: 0;
}

