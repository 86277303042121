html {
  // --primary: #007bff;
  --primary: #604b8f;
  --button-primary-hover: #1280B0;

  --gray-light: #ced4da;
  --gray-medium: #495057;
  --gray-dark: #343a40;
  --background: #ffffff;
  --background2: #eeeeee;
  --text: #604b8f;
  --text2: #8F989D;

  --font-title: 'Barlow', sans-serif;
  --font-text: 'Nunito', sans-serif;
  --font-size: 16px;

  --button-primary: var(--primary);
  --button-primary-hover: #522da1;
  --button-primary-bg: #341b69;

  --button-secundary: var(--secondary);
  --button-secundary-hover: #0c6489;
  --button-secundary-bg: #094b67;

  --screen-sm-min: 576px;
  --screen-md-min: 768px;
  --screen-lg-min: 992px;
  --screen-xl-min: 1200px;


  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--button-primary);
    --bs-btn-border-color: var(--button-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--button-primary-bg);
    --bs-btn-hover-border-color: var(--button-primary-hover);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--button-primary-hover);
    --bs-btn-active-border-color: var(--button-primary-bg);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--button-primary);
    --bs-btn-disabled-border-color: var(--button-primary);
  }


  .btn-outline-primary {
    --bs-btn-color: var(--button-primary);
    --bs-btn-border-color: var(--button-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--button-primary);
    --bs-btn-hover-border-color: var(--button-primary);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--button-primary);
    --bs-btn-active-border-color: var(--button-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--button-primary);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--button-primary);
    --bs-gradient: none;

    &:hover {
      svg {
        color: #fff;
      }
    }
  }
}

@import './general.scss';