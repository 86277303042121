.sidebar{
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  min-width: 60px;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
.link {
  color: var(--primary);
}

.link.active {
  svg {
    color: red;
  }
  color: red;
}

@media (max-width: 768px) { //TODO ARREGLAR VARIABLE 
  .sidebar{
      flex-direction: row;
      width: 100%;
      height: 60px;
      border-radius: 0.5rem 0.5rem 0 0;
  }
}