
.main {
    display: flex;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    width: 100%;
}

.main-body {
    padding: 2rem;
    width: 100%;
}

@media (max-width: 768px) { //TODO ARREGLAR VARIABLE 
    .main{
        flex-direction: column-reverse;
        justify-content: space-between;
    }

    .main-body {
        padding: 1rem;
    }
}