
//--------------------------------------------------------------------------------------
// LOADING
//--------------------------------------------------------------------------------------


.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.ball {
  width: 20px !important;
  height: 20px !important;;
  border-radius: 11px;
  margin: 0 10px;

  animation: 2s bounce ease infinite;
}

.blue {
  @extend .ball;
  background-color: #4285f5;
}

.red {
  @extend .ball;
  background-color: #ea4436;
  animation-delay: 0.25s;
}

.yellow {
  @extend .ball;
  background-color: #fbbd06;
  animation-delay: 0.5s;
}

.green {
  @extend .ball;
  background-color: #34a952;
  animation-delay: 0.75s;
}

@keyframes bounce {
  50% {
    transform: translateY(25px);
  }
}
